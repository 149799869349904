import create from "zustand";
import React from "react";
import { createRoot } from "react-dom/client";
import { createPortal } from "react-dom";

import { CheckOut } from "./components/CheckOut";
import { Basket } from "./components/Basket";
import { CartHeaderButton } from "./components/CartHeaderButton";

import { vanillaBasketStore } from "./vanillaBasketStore";

// web components (rely on dom properties + python)
import "./components/AddToCartButton";
import "./components/QuantityCounter";
import "./components/VariantSelect";
import "./components/ProductInfoTabs.js";
import "./components/ProductCardImage";
import "./components/ImageGallery.js";
import "./components/SortBy.js";

export const useBasketStore = create(vanillaBasketStore);

export const PortalComponent = ({ elementId, children }) => {
	const element = document.getElementById(elementId);
	if (element) {
		return createPortal(children, element);
	}
};

const App = () => {
	const fetchBasket = useBasketStore((state) => state.updateBasket);
	const basketOpen = useBasketStore((state) => state.dropdownOpen);

	React.useEffect(() => {
		fetchBasket();
	}, []);

	React.useEffect(() => {
		if (basketOpen) {
			document.body.classList.add("basket-open");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		} else {
			document.body.classList.remove("basket-open");
		}
	}, [basketOpen]);

	return (
		<>
			<PortalComponent elementId="store">
				<Basket />
			</PortalComponent>
			<PortalComponent elementId="checkout">
				<CheckOut />
			</PortalComponent>
			<PortalComponent elementId="cart-button">
				<CartHeaderButton />
			</PortalComponent>
		</>
	);
};

const domContainer = document.querySelector("#store, #checkout");
if (domContainer) {
	const root = createRoot(domContainer);
	root.render(<App />);
}
