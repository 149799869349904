export class ImageGallery extends HTMLElement {
	constructor() {
		super();

		this.mainImages = this.querySelectorAll("[data-img-main]");
		this.otherImages = this.querySelectorAll("[data-img-other]");
	}

	showImage(imageNumber) {
		this.mainImages.forEach((image) => {
			image.classList.remove("active");
		});

		this.querySelector(`[data-img-main="${imageNumber}"]`).classList.add(
			"active",
		);
	}

	connectedCallback() {
		this.otherImages.forEach((image) => {
			image.addEventListener("mouseenter", () => {
				this.showImage(image.dataset.imgOther);
			});
			image.addEventListener("click", () => {
				this.showImage(image.dataset.imgOther);
			});
		});

		this.showImage(1);
	}
}

window.customElements.define("image-gallery", ImageGallery);
