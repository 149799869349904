export class ProductCardImage extends HTMLElement {
	constructor() {
		super();
		this.otherImage = this.querySelector(".product-card__other-image");
	}

	connectedCallback() {
		if (!this.otherImage) {
			return;
		}
		this.addEventListener("mouseenter", () => {
			this.otherImage.classList.add("is-active");
		});
		this.otherImage.addEventListener("mouseleave", () => {
			this.otherImage.classList.remove("is-active");
		});
	}
}

window.customElements.define("product-card-image", ProductCardImage);
