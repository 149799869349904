export class VariantSelect extends HTMLSelectElement {
	connectedCallback() {
		this.addEventListener("change", (_) => {
			this.updateCartButton();
		});
		this.cartButton = document.querySelector("button[is='add-to-cart-button']");
		if (this.value) {
			this.updateCartButton();
		}
	}

	updateCartButton() {
		let variantOptions = this.cartButton.getAttribute("variants");
		if (!variantOptions) {
			this.cartButton.setAttribute(
				"variants",
				JSON.stringify({
					[this.name]: this.value,
				}),
			);
		} else {
			variantOptions = JSON.parse(variantOptions);
			variantOptions[this.name] = this.value;
			this.cartButton.setAttribute("variants", JSON.stringify(variantOptions));
		}
	}
}

window.customElements.define("variant-select", VariantSelect, {
	extends: "select",
});
