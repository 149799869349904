export class ProductInfoTabs extends HTMLElement {
	constructor() {
		super();

		this.tabButtons = this.querySelectorAll("[data-button]");
		this.tabContents = this.querySelectorAll("[data-content]");
	}

	showTab(tab) {
		this.tabContents.forEach((tabContent) => {
			tabContent.classList.remove("active");
		});

		this.tabButtons.forEach((tabButton) => {
			tabButton.classList.remove("active");
		});

		this.querySelector(`[data-content="${tab}"]`).classList.add("active");
		this.querySelector(`[data-button="${tab}"]`).classList.add("active");
	}

	connectedCallback() {
		this.tabButtons.forEach((tabButton) => {
			tabButton.addEventListener("click", () => {
				this.showTab(tabButton.dataset.button);
			});
		});

		this.showTab(1);
	}
}

window.customElements.define("product-info-tabs", ProductInfoTabs);
