import countryList from "country-list-js";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useBasketStore } from "../store";
import { apiHeaders } from "../utils";

const FormField = ({
	label,
	name,
	register,
	errors,
	type = "text",
	pattern,
	title = "",
}) => {
	return (
		<div className="form-field">
			<label className="form-field__label" htmlFor={name}>
				{label}*
			</label>
			<input
				required
				type={type}
				pattern={pattern}
				title={title}
				className="form-field__field"
				{...register(name, { required: true })}
			/>
		</div>
	);
};

const SelectField = ({ label, name, register, errors, children }) => {
	return (
		<div className="form-field">
			<label className="form-field__label" htmlFor={name}>
				{label}*
			</label>
			<select
				required
				className="form-field__field"
				{...register(name, { required: true })}
			>
				{children}
			</select>
		</div>
	);
};

export const DeliveryForm = () => {
	const {
		register,
		formState: { errors },
		watch,
	} = useFormContext();
	const VEGEMITE = "Australia";
	const watchCountry = watch("country", VEGEMITE);
	const domesticShippingMethod = watch("shippingMethod", "standard");
	const [states, setStates] = React.useState(
		countryList.findByName(VEGEMITE).provinces,
	);
	const { updateBasket } = useBasketStore();

	const countryOptions = countryList
		.names()
		.sort((a, b) => a > b)
		.map((country) => {
			return (
				<option key={country} value={country}>
					{country}
				</option>
			);
		});

	const stateOptions = states.map((state) => {
		return (
			<option key={state.name} value={state.name}>
				{state.name}
			</option>
		);
	});

	const addShipping = (_) => {
		const shipping =
			watchCountry === VEGEMITE
				? domesticShippingMethod === "standard"
					? "domestic-standard"
					: "domestic-express"
				: "international";
		fetch("/store-api/basket/extra/", {
			method: "PUT",
			headers: apiHeaders(),
			body: JSON.stringify({
				extra: {
					shipping: shipping,
				},
			}),
		}).then((_) => {
			updateBasket();
		});
	};

	useEffect(() => {
		const provinces = countryList.findByName(watchCountry).provinces;
		if (provinces) {
			setStates(provinces);
		} else {
			setStates([]);
		}
		addShipping();
	}, [watchCountry]);

	useEffect(() => {
		addShipping();
	}, [domesticShippingMethod]);

	return (
		<>
			<h4 className="checkout__subtitle">Shipping address</h4>
			<FormField
				label="Full Name"
				name="name"
				register={register}
				errors={errors}
			/>
			<FormField
				label="Email"
				name="email"
				type="email"
				pattern="^(.+)@(.+)\.(.+)$"
				title="A valid email address."
				register={register}
				errors={errors}
			/>
			<FormField
				label="Address"
				name="address"
				register={register}
				errors={errors}
			/>
			<FormField label="City" name="city" register={register} errors={errors} />
			<SelectField
				label="Country"
				name="country"
				register={register}
				errors={errors}
			>
				{countryOptions}
			</SelectField>
			{states.length > 0 && (
				<SelectField
					label="State/Province"
					name="state"
					register={register}
					errors={errors}
				>
					{stateOptions}
				</SelectField>
			)}
			<FormField
				label="Postcode"
				name="postcode"
				register={register}
				errors={errors}
			/>

			{watchCountry == VEGEMITE ? (
				<>
					<h4 className="checkout__subtitle">Shipping method</h4>
					<div className="checkout__shipping-methods">
						<label htmlFor="shipping_standard">
							<input
								{...register("shippingMethod")}
								type="radio"
								value="standard"
								id="shipping_standard"
								defaultChecked={true}
							/>
							Standard ($12.00)
						</label>
						<label htmlFor="shipping_express">
							<input
								{...register("shippingMethod")}
								type="radio"
								value="express"
								id="shipping_express"
							/>
							Express ($15.66)
						</label>
					</div>
				</>
			) : null}
		</>
	);
};
