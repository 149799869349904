import React from "react";
import { useBasketStore } from "../store";

export const CartHeaderButton = () => {
	const { basket, loading, dropdownOpen, toggleDropdown } = useBasketStore();

	return (
		<button
			onClick={() => toggleDropdown()}
			className={
				"l-site-header__icon-link l-site-header__icon-link--cart" +
				(loading ? " is-loading" : "") +
				(basket.items?.length > 0 ? " has-items" : "") +
				(dropdownOpen ? " is-open" : "")
			}
		>
			<svg viewBox="0 0 21.68 20.05">
				<use xlinkHref="#icon-cart"></use>
			</svg>
		</button>
	);
};
