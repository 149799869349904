import create from "zustand/vanilla";

export const vanillaBasketStore = create(() => {
	return {
		basket: {},
		loading: true,
		updateBasket: async () => {
			const response = await fetch("/store-api/basket");
			const data = await response.json();
			vanillaBasketStore.setState({ basket: data, loading: false });
		},
		dropdownOpen: false,
		openDropdown: () => {
			vanillaBasketStore.setState({ dropdownOpen: true });
		},
		toggleDropdown: () => {
			vanillaBasketStore.setState((state) => ({
				dropdownOpen: !state.dropdownOpen,
			}));
		},
	};
});
