import React from "react";
import { apiHeaders } from "../utils";
import { useBasketStore } from "../store";

export const CouponForm = () => {
	const errorOutput = React.useRef(null);
	const couponInput = React.useRef(null);
	const { updateBasket } = useBasketStore();

	const applyCoupon = (couponCode) => {
		fetch("/store-api/basket/extra/", {
			method: "PUT",
			headers: apiHeaders(),
			body: JSON.stringify({
				extra: {
					coupon: couponCode,
				},
			}),
		}).then((_) => {
			updateBasket();
		});
	};

	const checkCoupon = () => {
		const couponCode = couponInput.current.value;
		if (!couponCode) return;
		errorOutput.current.innerText = "";
		fetch(`/store-api/coupons/${couponCode}/`, {
			method: "GET",
			headers: apiHeaders(),
			credentials: "include",
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else if (response.status === 404) {
					throw new Error("No coupon found for this code.");
				}
			})
			.then((data) => {
				if (!data.is_valid) {
					throw new Error("This coupon has expired.");
				} else {
					applyCoupon(couponCode);
					couponInput.current.value = "";
				}
			})
			.catch((e) => {
				// 404 usually
				errorOutput.current.innerText = e.message;
			});
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			// We don't want the form to submit but we do want to check the coupon
			event.preventDefault();
			checkCoupon();
		}
	};

	return (
		<>
			<input
				onKeyDown={handleKeyPress}
				ref={couponInput}
				className="form-field__field form-field__field--white"
				type="text"
				name="coupon"
				placeholder="Coupon code"
			/>
			<div className="checkout__coupon-error" ref={errorOutput}></div>
			<button
				onClick={checkCoupon}
				type="button"
				className="checkout__coupon-btn"
			>
				Apply Coupon
			</button>
		</>
	);
};
