export class SortBy extends HTMLElement {
	constructor() {
		super();

		this.sortByButton = this.querySelector(".sort-by__button");
		this.optionsContainer = this.querySelector(".sort-by__options-container");
		this.optionsButtons = this.querySelectorAll("[data-query]");
		this.buttonIcon = this.sortByButton.querySelector("span");
	}

	toggleOptions() {
		this.optionsContainer.classList.toggle("active");
	}

	connectedCallback() {
		this.sortByButton.addEventListener("click", () => {
			this.toggleOptions();
		});
		this.optionsButtons.forEach((button) => {
			button.addEventListener("click", () => {
				const url = new URL(window.location.href);
				const query = button.getAttribute("data-query");
				url.searchParams.set("sort", query);
				window.location.href = url.toString();
			});
		});
		document.addEventListener("click", (event) => {
			const isClickInside = this.contains(event.target);
			if (!isClickInside) {
				this.optionsContainer.classList.remove("active");
			}
		});
	}
}

window.customElements.define("sort-by", SortBy);
